import React, { useState, useEffect, useRef } from "react";
import Web3 from 'web3';

import Appbar from "../Components/Appbar";
import {features, socials, heroesB, blocks, partners, heroes, heroesMob, currencies, oracle_contract_abi, oracle_contract_address, nft_contract_abi, nft_contract_address, TokenConstants} from '../Constants';
import Aos from 'aos';

import './styles.css';
import 'aos/dist/aos.css';


// Images
import cat from '../assets/images/cat.png';
import dog from '../assets/images/dog.png';
import logo from '../assets/images/wm_logo.png';
import binance from '../assets/partners/binanceChain.png';

import worlds from '../assets/images/worlds.png';
import mobWorlds from '../assets/images/mobWorlds.png';
import Checkmark from '../assets/icons/Checkmark.png';
import Bear_And_Friend from '../assets/images/Bear_And_Friend.png';
import Icy_World from '../assets/images/Icy_World.png';
import Haunted_House from '../assets/images/Haunted_House.png';
import Clouds from '../assets/images/Clouds.png';
import stars from '../assets/images/stars.png';
import starsMob from '../assets/images/starsmob.png';

import Road_1 from '../assets/backgrounds/road1.png';
import Road_2 from '../assets/backgrounds/road2.png';
import unchecked from '../assets/images/Unchecked_Road_Point.png';
import checked from '../assets/images/Checked_Road_Point.png';
import downarrow from '../assets/icons/down_arrow.png';
import blur from '../assets/backgrounds/blur.png';




export default function Home() {
  const web3 = new Web3(window.ethereum);


    const play2earn_sec = useRef(null);
    const howtoplay_sec = useRef(null);
    const nfts_sec = useRef(null);
    const roadmap_sec = useRef(null);

    const[heroInfo, setHeroInfo] = useState();
    const [currency, setCurrency] = useState({
        name:currencies[0]['name'],
        img:currencies[0]['img'],
        vis:false,
        price:0
    });
    const [w3Attached, setW3Attached] = useState(null);
    const [w3Provider, setW3Provider] = useState(null);
    const [isMob, setIsMob] = useState(false);
    const [bgPosX, setBgPosX] = useState(0);
    const [bgPosY, setBgPosY] = useState(0);
    const [heroesList, setHeroesList] = useState(heroes)
    const [displayModal, setDisplayModal] = useState(false);
    const [hprice, setHPrice] = useState(null);
    

    useEffect(() => {
        (function(a)
          {
            if((/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))){
              if (parseInt(window.innerWidth) > parseInt(window.innerHeight)){
                setIsMob(false);
                
              }else{
                setIsMob(true);
                setHeroesList(heroesMob);
              }
        }
          })
          (navigator.userAgent||navigator.vendor||window.opera);
    },[isMob]);

    useEffect(() => {
        CheckHero(1);
        Aos.init({duration:1000});

        window.addEventListener("resize", handleResize);
    },[]);
    
    
   
    async function BuyHero(){
        var cost;
        if(currency.name === "BNB"){
            cost = hprice;
        }else{
            cost = 0.0001;
        }
        if(w3Attached !== null){
            const wizardContract = new web3.eth.Contract(nft_contract_abi, nft_contract_address);
            var myAddress = web3.utils.toChecksumAddress(w3Provider.selectedAddress.toString());
            await  wizardContract.methods.mint(myAddress,heroInfo.name.toString(),currency.name.toString()).send({
                from:myAddress,
                to:web3.utils.toChecksumAddress(nft_contract_address.toString()),
                value:web3.utils.toWei(cost.toString(), 'ether'),
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
                }).then(() => alert("Minted Successfully!")).catch(err => alert("Not Enough Ether"))
        }else{
            alert("Kindly connect your wallet!")
        }

    };

    async function GetPrice(_currName, pr) {
        const oracleContract = new web3.eth.Contract(oracle_contract_abi, oracle_contract_address);
        var price_in_usd = pr;
            await oracleContract.methods.getPriceFromBUSD(1,_currName).call()
            .then((price) => {
                setHPrice((price_in_usd/price).toPrecision(5));
            })
            .catch(() => {
                setHPrice(pr);
            })
    };

    async function ApproveToken(){
        if(currency.name === 'BNB'){
            BuyHero();
        }else{
            var cont =  handleApproved();
            var amount = web3.utils.toWei(hprice.toString(), 'ether');
            var token_contract = new web3.eth.Contract(cont.abi, cont.address);
               await token_contract.methods.approve(nft_contract_address, amount).send({
                   from:web3.utils.toChecksumAddress(w3Provider.selectedAddress.toString())
            })
               .then((res) => {
                if(res){
                        BuyHero();
                   }else{
                       alert("Cannot Approve this token currently!!!")
                   }
               })
               .catch((err) => console.log(err))
        }
    };


    const handleCurrencies = (e) => {
        if(w3Provider !== null){
            setCurrency({...currency,name:e.name,vis:false, img:e.img});
            GetPrice(e.name, heroInfo.price);
            
    }else{
        setCurrency({...currency,vis:false});
        alert("Connect your wallet to get current price!")
    }
    };

    const handleApproved = () => {
        var address = null;
        var abi = null;

        switch(currency.name){
            case "BUSD":
                return({
                        address: TokenConstants.BUSD.address,
                        abi : TokenConstants.BUSD.abi,
                    });
            case "BTC":
                return({
                    address : TokenConstants.BTC.address,
                    abi : TokenConstants.BTC.abi,
                });
            case "ETH":
                return({
                    address : TokenConstants.ETH.address,
                    abi : TokenConstants.ETH.abi
                });
            case "USDT":
                return({
                    address : TokenConstants.USDT.address,
                    abi : TokenConstants.USDT.abi
                });
            default:
                return;
        }
    };

    const handleResize = () => {
        var x = window.innerWidth;
        var y = window.innerHeight;
        var z = x / y;
         if(!isMob){
             if (z < 1) {
                 setHeroesList(heroesMob);
                 setIsMob(true);
             } else {
                 setIsMob(false);
                 setHeroesList(heroesB);
             }
         }
    };

    const Tracking = (e) => {
         setBgPosY(e.pageY*-0.05)
         setBgPosX(e.pageX * -0.009)
    };

    const CheckHero = (idx) => {
             if(!isMob){
                setHeroInfo(heroesB[idx]);
                GetPrice(currency.name, heroesB[idx].price);

             }else{
                 setHeroInfo(heroesList[idx]);
                GetPrice(currency.name, heroesList[idx].price);

             }
              
    };

    return(
        <div style={{backgroundColor:'#181642',width:'100%'}}>
            <div className={isMob ? "landing mob" : "landing"} onMouseMove={(e) => Tracking(e)}>
                <Appbar w3Attached={w3Attached} setW3Attached={setW3Attached} w3Provider={w3Provider} setW3Provider={setW3Provider} 
                play2earn={play2earn_sec} howtoplay={howtoplay_sec} chars={nfts_sec} roadmap={roadmap_sec}  
                displayModal={displayModal} setDisplayModal={setDisplayModal} isMob={isMob} typ="n"
                />
                <div className="land_cont">
                    <img src={dog} alt="dog" style= {isMob ? {display:'none'} :{left:bgPosX*3, top:bgPosY*2}}/>
                    <img src={cat} alt="cat" style={ isMob ? {display:'none'} :{left:bgPosX*3, top:bgPosY}}/>
                    <div className="land_info">
                        <img src={binance} alt="binanace"/>
                        <h3>Gaming NFT Metaverse where Magic comes True</h3>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="socials">
                        {socials.map((s,idx) => 
                            <a key={idx} href={s.href} target="_blank"><img src={s.img} alt={s.name}/></a>
                        )}
                        
                    </div>
                </div>
                {displayModal ?  
                    <div className="blurcont">
                        <img src={blur} id="blurImg"/>
                    </div>
                     : null}
            </div>

            <div className={isMob ? "introduction mob" : "introduction"} ref={play2earn_sec}>
                    <h2 >Play-to-earn on Wizardium</h2>
                    <p data-aos="fade-up">
                        Welcome to the crypto magic Metaverse full of mystical items and rare elements. Choose your Hero and have{'\n'}a
                        chance to dive into the world of magic and alchemy. Explore lands to find the elements and minerals, drop{'\n'}them to the
                        mighty cauldron and get the next Binance Smart Chain gems - Wizardium $WIZZY tokens, that can{'\n'}get you insane ROI.
                        Play to Earn, to make magic, to make money, to mine $WIZZY tokens, and to enjoy this{'\n'}amazing crypto journey.
                    </p> 
                        <img src={isMob ? mobWorlds : worlds} alt="worlds"  className={isMob ? "worlds mob" : "worlds"} data-aos="zoom-in-up"/>

                        <div className={isMob ? "features mob" : "features"} >
                            {features.map((f,idx) => 
                                <div key={idx} className={isMob ? "feature mob" : "feature"} data-aos="zoom-out">  
                                
                                    <div>
                                        <img src={Checkmark} alt="checkmark"/>
                                        <h3>{f.title}</h3>
                                    </div>
                                    <p>{f.desc}</p>
                                </div>
                            )}
                        </div>
            </div>

            <div className={isMob ? "learning mob" : "learning"} ref={howtoplay_sec}>
                <h2>How To Play</h2>
                    <div className={isMob ? "learn_desc mob" : "learn_desc"} data-aos="zoom-out-down">
                            <img src={Bear_And_Friend} alt="bear"/>
                            <h3>01<p>Buy your first NFT alchemist. The higher level of{"\n"}the alchemist - the faster  you will find elements{"\n"}and the higher profit you will get.</p></h3>
                            <img src={Icy_World} alt="icy"/>
                            <h3>02<p>Send them to the land of Wizardium to have{"\n"}a chance to find valuable elements, if luck{"\n"}turns you on you will be able to find extremely{"\n"}costly ones.</p></h3>
                            <img src={Haunted_House}/>
                            <img src={Clouds} alt="clouds"/>
                            <h3>03<p>Once found the NFT elements drop them to the{"\n"}magic cauldron to brew $WIZZY tokens.</p></h3>
                    </div>
            </div>
        
            <div className={isMob ? "heroes mob" : "heroes"} ref={nfts_sec}>
                <h2>Choose your hero</h2>
                <div className={isMob ? "h-sec mob" : "h-sec"}>
                    <div className={isMob ? "heroes-cont mob" : "heroes-cont"}>
                        {heroesList.map((h,idx) => 
                            <div
                                onClick={() => CheckHero(idx)}
                                key={idx}
                                className={isMob ? "hero mob" : "hero"} 
                                data-aos="flip-left"
                                >
                                <img src={h.img} alt="hero" style={typeof(heroInfo) !== 'undefined'? {border: h.name === heroInfo.name ? '4px groove #FED512' : ''} : {}} />
                                
                                </div>
                        )}
                    </div>
                    {typeof(heroInfo) !== "undefined" ? 
                    <div className={isMob ? "heroes-info mob" : "heroes-info"}>
                       <img src={heroInfo.img} alt="hero"/>
                        
                        <div className={isMob ? "infos mob" : "infos"}>
                            <h3>{heroInfo.name}</h3>
                            <p>{heroInfo.desc}</p>
                            <div className={isMob ? "skills mob" : "skills"}>
                                <img src={heroInfo.life} alt="life"/>
                                <img src={heroInfo.energy} alt="energy"/>
                                <img src={heroInfo.skill} alt="skill"/>
                            </div>
                           
                        </div>
                        <div className={isMob ? "btm_h mob" : "btm_h"}>
                                <div className={isMob ? "data mob" : "data"}>
                                    <h5>Current price</h5>
                                    <div className={isMob ? "currData mob" : "currData"}>
                                        <img src={currency.img} alt="currency"/>
                                        <h4>{hprice}</h4>
                                            {isMob ? 
                                                <p>(~${heroInfo.price})</p>
                                                :
                                                <div className={isMob ? "curency mob" : "curency"} onClick={() => setCurrency({...currency,vis:!currency.vis})}>
                                                    <p >{currency.name}</p>
                                                    <img src={downarrow} alt="downarrow"/>
                                                </div>
                                            }
                                                {currency.vis ?
                                                    <ul>
                                                        {currencies.map((c,idx) => 
                                                            <li key={idx} onClick={() => handleCurrencies(c)}>{c.name}</li>
                                                        )}
                                                    </ul>
                                                    :
                                                null
                                                }
                                               
                                </div>
                                {isMob ? <div className={isMob ? "curency mob" : "curency"} onClick={() => setCurrency({...currency,vis:!currency.vis})}>
                                            <p >{currency.name}</p>
                                            <img src={downarrow} alt="downarrow"/>
                                        </div>
                                        : 
                                        <p>(~${heroInfo.price})</p>
                                }
                            </div>
                                    <button onClick={() => ApproveToken()}>Buy</button>
                            </div>
                        </div>
                    :
                    <></>
                }
            </div>  
            </div>

            <div className={isMob ? "roadmap mob" : "roadmap"} ref={roadmap_sec}>
                <h2>Road map</h2>
                <div className={isMob ? "roads mob" : "roads"}>
                    <img src={isMob ? starsMob : stars} className={isMob ? "sr mob" : "sr"} alt="stars"/>
                    <img src={Road_1} id={isMob ? "rodmob2" : "rod1"} alt="road"/>
                    <div className={isMob ? "blocks mob" : "blocks"} data-aos="zoom-out-up">
                        {blocks.m1.map((m,idx) =>
                            <div key={idx} className={isMob ? "block mob" : "block"} >
                                <p>{m.title}</p>
                                <img src={idx > 1 ? unchecked : checked} alt="check"/>
                            </div> )}

                        </div>
                    </div>

                <div className={isMob ? "roads mob" : "roads"}>
                    <img src={isMob ? starsMob : stars} className={isMob ? "sr mob" : "sr"} alt="stars"/>
                    <img src={Road_2} id={isMob ? "rodmob2" : "rod1"} alt="road"/>
                    <div className={isMob ? "blocks2 mob" : "blocks2"} data-aos="zoom-out-down">
                        {blocks.m2.map((m,idx) => 
                            <div key={idx} className={isMob ? "block mob" : "block"}>
                            <p>{m.title}</p>
                            <img src={unchecked} alt="check"/>
                        </div>
                        )}
                    </div>
                </div>
                <h2 style={{fontSize:isMob ? "3.5vh" : "7vh"}}>...more to come</h2>
            </div>

            <div className={isMob ? "partners mob" : "partners"}>
                <div className={isMob ? "ps mob" : "ps"}>
                    <div className={isMob ? "part-stars mob" : "part-stars"} >
                        {partners.map((p,idx) => 
                        <img
                            key={idx}
                            src={p} 
                            data-aos="fade-right"
                            data-aos-offset="300"
                            data-aos-easing="ease-in-sine"
                            alt="partner"
                            />
                            
                        )}
                    </div>
                </div>
                    <div className={isMob ? "ftr mob" : "ftr"}>
                        <div className={isMob ? "ftr-l mob" : "ftr-l"}>
                            <a href="/"><img src={logo} alt="logo"/></a>
                            <p>This website is related to cryptocurrency DYOR{'\n'}and take your risks. The game is created in Play-To-Earn{'\n'}concept on Binance Smart Chain</p>
                        </div>
                        <div className={isMob ? "ftr-r mob" : "ftr-r"}>
                            <p>Contact information</p>
                            <p><a href="mailto:contact@wizardium.io">contact@wizardium.io</a></p>
                            <div className={isMob ? "ftr-socials mob" : "ftr-socials"}>
                            {socials.map((s,idx) => 
                                <a key={idx} href={s.href} target="_blank"><img src={s.img} alt={s.name}/></a>
                            )}
                            </div>
                        </div>
                    </div>
            </div>

            <div className="copyright">
                <p>Wizardium 2021. All Rights Reserved</p>
            </div>
        </div>
    );
};