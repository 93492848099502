import React, { useState } from "react";
import logo from '../../assets/images/wm_logo.png';
import close from '../../assets/icons/close.png';
import menu from '../../assets/icons/menuIcon.png';
import ConnectWallet from "../ConnectWallet";
import './styles.css'

export default function Appbar({
  w3Attached,setW3Attached,w3Provider,setW3Provider,
  play2earn,howtoplay,chars,roadmap, 
  displayModal, setDisplayModal, isMob,typ
})
{
    const [showMenu, setShowMenu] = useState(false);

      const Scrolling = (sec_label) => {
        window.scrollTo({
          top:sec_label.current.offsetTop,
          behavior:'smooth'
        })
      };

      const handleClicks = (e) => {
        switch(typ){
          case "n":
            return (
              <ul>
                <li><a onClick={() => Scrolling(play2earn)}>Play2Earn</a></li>
                <li><a onClick={() => Scrolling(howtoplay)}>How to play</a></li>
                <li><a onClick={() => Scrolling(chars)}>NFTs</a></li>
                <li><a onClick={() => Scrolling(roadmap)}>Roadmap</a></li>
                <li><a href="https://about.wizardium.io/" target="_blank">About</a></li>

                <li><a href="/WizardHut">Wizard hut</a></li>
              </ul>
            );
          case "w":
            return(
            <ul>
                <li><a href="/">Play2Earn</a></li>
                <li><a href="/">How to play</a></li>
                <li><a href="/">NFTs</a></li>
                <li><a href="/">Roadmap</a></li>
                <li><a href="https://about.wizardium.io/" target="_blank">About</a></li>
                <li><a href="/WizardHut">Wizard hut</a></li>
              </ul>
            );
            default:
              <ul>
                <li><a href="/">Play2Earn</a></li>
                <li><a href="/">How to play</a></li>
                <li><a href="/">NFTs</a></li>
                <li><a href="/">Roadmap</a></li>
                <li><a href="https://about.wizardium.io/" target="_blank">About</a></li>
                <li><a href="/WizardHut">Wizard hut</a></li>
              </ul>
        }
      };

      const connectedWallet = () => {
        if(w3Attached !== null){
          const address = (w3Provider.selectedAddress).split("");
          return (<span>{address[0]+address[1]+address[2]+address[3]+address[4]+address[5] + "..."+ address[38] + address[39]+address[40]+address[41]}</span>)
        }
      };


    return(
      isMob ? 
        <div className={showMenu ?  "appbarmob" : "appbarmob menu"}>
          <div className={showMenu ? "leftmenu" : "leftmenu hid"}>
            <div className="menu-top">
                <a href="/"><img src={logo} alt="logo"/></a>
                <img src={close} alt="close" onClick={() => setShowMenu(false)}/>
            </div>
            {handleClicks()}
          </div>
          <div className="topbar">
            <img src={menu} alt="menu" onClick={() => setShowMenu(true)}/>
            {w3Attached !== null? 
              <div className="button mob">
                <span>Connected: {connectedWallet()}</span>
              </div>
            :
            <ConnectWallet 
              w3Attached={w3Attached} setW3Attached={setW3Attached} 
              w3Provider={w3Provider} setW3Provider={setW3Provider} 
              displayModal={displayModal} setDisplayModal={setDisplayModal}
            />
          }
            

          </div>
        </div>
       :
      <div className="appbar">
        <a href="/"><img src={logo} alt="logo"/></a>
         {handleClicks()}
          {w3Attached !== null ? 
              <div className= "button">
              <span>Connected: {connectedWallet()}</span>
          </div>
              : 
              <ConnectWallet w3Attached={w3Attached} setW3Attached={setW3Attached} w3Provider={w3Provider} setW3Provider={setW3Provider} displayModal={displayModal} setDisplayModal={setDisplayModal}/>
          }
      </div>
    );
};