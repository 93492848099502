import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import Home from './Home';
import Heroes from './Heroes'
import './index.css'

var hist = createBrowserHistory();
const rootElement = document.getElementById("root");


ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/WizardHut" component={Heroes} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>,
  rootElement
);
