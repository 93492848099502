import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { BscConnector } from '@binance-chain/bsc-connector';
import { Button } from '../Button';
import bsc from '../../assets/partners/BSC_Logo.png';

export default function ConnectWallet({
  w3Attached,
  setW3Attached,
  w3Provider,
  setW3Provider,
  displayModal,
  setDisplayModal,
}) {
  const [w3Modal, setW3Modal] = useState(null);

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          1: 'https://bsc-dataseed.binance.org/',
          2: 'https://bsc-dataseed1.defibit.io/',
          3: 'https://bsc-dataseed1.ninicoin.io/',
        },
        // network: "binance",
        infuraId: '',
      },
    },

    // injected: {
    //   display: {
    //     logo: 'data:image/gif;base64,INSERT_BASE64_STRING',
    //     name: 'Injected',
    //     description: 'Connect with the provider in your Browser testik',
    //   },
    //   package: null,
    // },

    // 'custom-binance': {
    //   display: {
    //     logo: bsc,
    //     name: 'Binance',
    //     description: 'Connect to your binance provider account',
    //   },
    //   package: BscConnector,
    //   options: {
    //     supportedChainIds: [56, 97],
    //   },
    //   connector: async (BscConnector, options) => {
    //     const provider = new BscConnector(options);

    //     await provider.activate();
    //     await provider.getProvider();
    //     await provider.getAccount();
    //     await provider.getChainId();

    //     return provider;
    //   },
    // },
  };

  useEffect(() => {
    initMain();
  }, [w3Provider, w3Attached]);

  async function initMain() {
    const web3Modal = new Web3Modal({
      network: 'mainnet',
      cacheProvider: false,
      // disableInjectedProvider: true,
      // disableInjectedProvider: false,
      providerOptions,
      theme: {
        background: 'rgba(66, 0, 97, 1)',
      },
    });

    if (web3Modal.cachedProvider !== '') {
      web3Modal.clearCachedProvider();
      setW3Modal(web3Modal);
    }
    if (w3Modal === null) {
      setW3Modal(web3Modal);
    }
  }

  async function connectToWallet() {
    console.log('try coonect 1');
    if (!(w3Provider === null || typeof w3Provider === 'undefined')) {
      return;
    }
    try {
      setDisplayModal(true);
      const provider = await w3Modal.connect();
      console.log('try coonect');
      setW3Provider(provider);
      if (provider != null && typeof provider != 'undefined') {
        setW3Attached(new Web3(provider));
        setDisplayModal(false);
      } else {
        setDisplayModal(false);
      }
    } catch {
      console.log('Modal Closed By User');
      setDisplayModal(false);
    }
  }

  return (
    <Button
      title="Connect wallet"
      command={w3Provider ? console.log('Connected') : connectToWallet}
    />
  );
}
