import React, { useEffect, useState } from "react";
import Web3 from 'web3';


import Appbar from "../Components/Appbar";
import dot from '../assets/icons/DOT.png';
import leftArrow from '../assets/icons/leftArrow.png';
import rightArrow from '../assets/icons/rightArrow.png';
import blur from '../assets/backgrounds/blur.png';


import './styles.css';
import { filterheroes, nft_contract_abi, nft_contract_address } from "../Constants";

export default function Heroes() {

  const web3 = new Web3(window.ethereum);


    const [heroIdx, setHeroIdx] = useState(0);
    const [w3Attached, setW3Attached] = useState(null);
    const [w3Provider, setW3Provider] = useState(null);
    const [displayModal, setDisplayModal] = useState(false);
    const [isMob, setIsMob] = useState(false);

    const [myHeroes, setMyHeroes] = useState([])

    useEffect(() => {
        if(myHeroes.length === 0){
            GetID();
        }
    });

    useEffect(() => {
        (function(a)
          {
            if((/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| ||a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))){
              if (parseInt(window.innerWidth) > parseInt(window.innerHeight)){
                setIsMob(false);
                
              }else{
                setIsMob(true);
              }
        }
          })
          (navigator.userAgent||navigator.vendor||window.opera);
    },[isMob]);

    const handleHeroIdx = (k) => {
        if(k === "p"){
            if(heroIdx < myHeroes.length -1){
                setHeroIdx(heroIdx + 1);
            }

        }else{
            if((heroIdx-1) > 0 ){
                setHeroIdx(heroIdx - 1)
            }
        }
    };

    async function GetID() {
        if(w3Attached !== null){
            const contract = new web3.eth.Contract(nft_contract_abi, nft_contract_address);
            var myAddress = web3.utils.toChecksumAddress(w3Provider.selectedAddress.toString());

            await contract.methods.WalletOfOwner(myAddress).call()
            .then((res) => {
                GetURI(res)
            })
            .catch(err => {
                console.log(err)
            })
        }
    };

    async function GetURI(id){
        try{
            const contract = new web3.eth.Contract(nft_contract_abi, nft_contract_address);
            for(var i = 0; i<id.length; i++){
                await contract.methods.tokenURI(parseInt(id[i])).call()
                    .then((res) => parseImages(res))
                    .catch((err) => console.log(err))
            } 
        }catch{
            setMyHeroes([]);
        }
       
    };

    async function parseImages(res_url){
        await fetch(res_url).then(res => res.json())
            .then(imgs => {
                var nft_img = imgs['image'] 
                setMyHeroes(myHeroes => ([...myHeroes, nft_img]))
            }).catch((err) => {
                console.log(err)
            });
    };

    return(
        <div className={isMob ? "wizheroes-cont mob" : "wizheroes-cont"}>
            <Appbar typ="w" w3Attached={w3Attached} setW3Attached={setW3Attached} w3Provider={w3Provider} setW3Provider={setW3Provider}
                displayModal={displayModal} setDisplayModal={setDisplayModal} isMob={isMob}
            />

            <div className={isMob ? "wizhero-holder mob" : "wizhero-holder"}>
                <div className={isMob ? "wizhero-img mob" : "wizhero-img"}>
                    {typeof(myHeroes) !== "undefined" && myHeroes.length > 0 ? 
                        <img  src={myHeroes[heroIdx]} alt="hero"/>
                    :
                    <h2><span>Kindly</span>{'\n'}Connect your wallet to{'\n'}display your heroes</h2>
                }
                </div>
                <div className={isMob ? "wizhero-icons mob" : "wizhero-icons"}>
                    <img src={leftArrow} onClick={() => handleHeroIdx('m')} alt="leftarrow"/>
                    <div>
                        <img src={dot} style={{transform:heroIdx === 0 ?'scale(1.3)' : ''}} alt="dot"/>
                        <img src={dot} style={{transform:heroIdx === 1 ?'scale(1.3)' : ''}} alt="dot"/>
                        <img src={dot} style={{transform:heroIdx > 1 ?'scale(1.3)' : ''}} alt="dot"/>
                    </div>
                    <img src={rightArrow} onClick={() => handleHeroIdx('p')} alt="rightarrow"/>
                </div>
            </div>
            {displayModal ?  
                    <div className="blurcont">
                        <img src={blur} id="blurImg" alt="blur"/>
                    </div>
                     : null}
        </div>
    );
};